<template>
    <div>
        <v-carousel v-if="aInitialImages.length > 0" v-model="iCarousel" class="content-carousel" eager
            :continuous="false" :touchless="false" delimiter-icon="mdi-minus" :height="iheight">
            <v-carousel-item v-for="(item, i) in aInitialImages" :key="i">
                <vue-photo-zoom-pro :scale="7" :width="200" class="content-img-zoom" :high-url="item.xlg">
                    <div>
                        <img class="img-op" :src="item.xlg" />
                    </div>
                </vue-photo-zoom-pro>
            </v-carousel-item>
        </v-carousel>
        <div v-else class="content-empty-carousel">
            <div class="content-img-empty-carousel">
                <span class="material-icons icon-image-empty-carousel"> image </span>
                <p class="txt-empty-carousel">Sin imágenes</p>
            </div>
        </div>
        <div v-if="bInitialImages" >
            <div class="content-info-img-initial-weight">
                <div class="content-txt-info-img">
                    <p class="txt-info-img">{{ sImgTitle }} </p>
                </div>
                <div class="content-Initial-weight">
                    <p class="txt-initial-weight">{{ formatMoneyGlobal(oItem.dInitialWeight) }}Kg</p>
                    <p class="txt-info-initial-weight">Pesaje inicial</p>
                </div>
            </div>
            <div class="content-info-initial-weight">
                <p class="text-title">Comentarios iniciales</p>
                <p v-if="(!oItem.sComments || oItem.sComments === 'undefined' || oItem.sComments === 'Sin comentarios.')" class="text-data-empty text-start">
                    {{ emptyDataAddress }}
                </p>
                <p v-else class="text-information-global text-comments" >
                    {{ oItem.sComments }}
                </p>
            </div>
        </div>
        <div v-else class="content-info-img-raw-material">
            <div class="content-info-name-code-raw-material">
                <v-container fluid>
                    <div class="content-name-raw-material">
                        <p class="txt-name-raw-material">{{ oItem.sNameRawMaterial }}</p>
                    </div>
                    <div class="content-code-raw-material">
                        <p class="txt-code-raw-material">{{ oItem.sCodeRawMaterial }}</p>
                    </div>
                </v-container>
            </div>
            <div class="content-info-all-raw-material">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" :sm="bShowAmount ? 4 : 6" :md="bShowAmount ? 4 : 6" :lg="bShowAmount ? 4 : 6"
                            :xl="bShowAmount ? 4 : 6">
                            <p class="text-title">Responsable</p>
                            <p class="text-information">{{ oItem.oSpecialist.sFullname }}</p>
                        </v-col>
                        <v-col cols="12" :sm="bShowAmount ? 2 : 6" :md="bShowAmount ? 2 : 6" :lg="bShowAmount ? 2 : 6"
                            :xl="bShowAmount ? 2 : 6">
                            <p class="text-title">Peso</p>
                            <p class="text-information">{{
                                    formatMoneyGlobal(oItem.dWeightRawMaterial)
                            }}
                                {{ oItem.sMeasurementUnitRawMaterial }}</p>
                        </v-col>
                        <v-col v-if="bShowAmount" cols="12" sm="3" md="3" lg="3" xl="3">
                            <p class="text-title">Precio unitario</p>
                            <p class="text-information">${{
                                    formatMoneyGlobal(oItem.dUnitPriceRawMaterial)
                            }}
                                MXN</p>
                        </v-col>
                        <v-col v-if="bShowAmount" cols="12" sm="3" md="3" lg="3" xl="3">
                            <p class="text-title">Subtotal</p>
                            <p class="text-information">${{
                                    formatMoneyGlobal(oItem.dSubtotalRawMaterial)
                            }}
                                MXN</p>
                        </v-col>
                        <v-col cols="12">
                            <p class="text-title">Comentarios</p>
                            <p v-if="(!oItem.sComments || oItem.sComments === 'undefined' || oItem.sComments === 'Sin comentarios.')" class="text-data-empty text-start">
                                {{ emptyDataAddress }}
                            </p>
                            <p v-else class="text-information-global text-comments" >
                                {{ oItem.sComments }}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bDialogImgsOP: Boolean,
        oItem: Object,
        bInitialImages: Boolean,
        iCarouselRawMaterials: Number
    },
    data() {
        return {
            iCarousel: 0,
            sImgTitle: "Fotografía pesaje inicial",
            aShowAmount: ["OPGeneralDetail", "OPPayableDetail"],
            iheight: 0,
            aInitialImages: [],
            emptyDataAddress: "Sin información.",
            bDisabledZoomImg: true,

        }
    },
    beforeMount() {
        this.fillInitialImagesArray(this.oItem);
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            // size to range
            if (window.innerWidth < 599.99) {
                this.iheight = 200;
            } else if (window.innerWidth > 599.99 && window.innerWidth < 959) {
                this.iheight = 300;
            } else {
                this.iheight = 400;
            }

        },
        fillInitialImagesArray(oItem) {
            this.aInitialImages = [];
            if (this.bInitialImages) {
                if (this.ObjEmptyGlobal(oItem.oInitialImages.oInitialWeightImageKey) || this.ObjEmptyGlobal(this.ObjEmptyGlobal(oItem.oInitialImages.oLicensePlateImageKey))) {
                    this.aInitialImages.push(oItem.oInitialImages.oInitialWeightImageKey, oItem.oInitialImages.oLicensePlateImageKey)
                }
            } else {
                for (let i = 0; i < oItem.aImages.length; i++) {
                    const element = oItem.aImages[i];
                    this.aInitialImages.push(element.oSizes)
                }
            }

        }
    },
    computed: {
        bShowAmount() {
            return (
                this.aShowAmount.filter((e) => e === this.$route.name)
                    .length > 0
            );
        }
    },
    watch: {
        bDialogImgsOP() {
            if (this.bDialogImgsOP) {
                this.fillInitialImagesArray(this.oItem);
            } else {
                this.iCarousel = 0;
            }
        },
        iCarouselRawMaterials() {

        },
        iCarousel() {
            switch (this.iCarousel) {
                case 0:
                    this.sImgTitle = "Fotografía pesaje inicial";
                    break;
                case 1:
                    this.sImgTitle = "Fotografía de la placa";
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped>
.content-card-carousel {
    display: flex;
    justify-content: center;
    position: relative;
}

.btn-arrow-left-raw-material {
    position: absolute;
    left: 10px;
    top: 65vh;
    background-color: var(--primary-color-background-btn-arrow) !important;
}

.icon-arrow-left-raw-material {
    color: var(--primary-color-text-arrow-carousel);
    margin-left: 5px;
}

.icon-arrow-right-raw-material {
    color: var(--primary-color-text-arrow-carousel);
    margin-left: 0px;
}

.btn-arrow-right-raw-material {
    position: absolute;
    right: 10px;
    top: 65vh;
    background-color: var(--primary-color-background-btn-arrow) !important;
}

.container-dialog-imgs-op {
    padding: 20px 0px 0px 0px !important;
    width: 95%;
}

.content-carousel {
    margin-top: 20px;
}

.content-img-zoom {
    width: 100%;
}

.img-op {
    object-fit: fill;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    width: 100%;
}

.content-empty-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    max-height: 520px;
    min-height: 520px;
    width: 100%;
}

.content-img-empty-carousel {
    position: absolute;
    width: auto;
    /* background-color: red; */
    /* left: 50%; */
}

.icon-image-empty-carousel {
    color: var(--primary-color-text);
    left: 40%;
    bottom: 16vh;
    font-size: 200px;
}

.txt-empty-carousel {
    color: var(--primary-color-text);
    font-family: "pop-Semibold";
    text-align: center;

}

.text-title {
    text-align: start;
    margin-bottom: 2px;
    color: var(--primary-color-text-title-detail);
    font-family: "pop-Regular";
    opacity: 1;
    letter-spacing: 0px;
}

.text-information {
    text-align: start;
    margin-bottom: 5px;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
}

.text-comments {
    text-align: start;
    margin-bottom: 5px;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
}

/*#region STYLE INITIAL WEIGHT */
.content-info-img-initial-weight {
    display: flex;
    width: 100%;
    padding: 20px 20px 20px 20px;
}

.content-txt-info-img {
    display: flex;
    align-items: center;
    width: 50%;
}

.txt-info-img {
    font-size: 25px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.content-Initial-weight {
    text-align: end;
    width: 50%;
}

.txt-initial-weight {
    font-size: 20px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-info-initial-weight {
    font-size: 12px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.content-info-initial-weight{
    padding: 10px 20px;
}

/*#endregion STYLE INITIAL WEIGHT */


/*#region STYLE RAW MATERIAL  */
.content-info-name-code-raw-material {
    width: 100%;
    padding: 10px 20px 10px 20px;

}

.content-info-all-raw-material {
    padding: 10px 20px 10px 20px;

}

.txt-name-raw-material {
    text-align: start;
    font-size: 25px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-code-raw-material {
    text-align: start;

    font-size: 15px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-Regular";
}

.txt-title {
    font-size: 13px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
}

.txt-value {
    font-size: 14px;
    margin-bottom: 0px;
    color: var(--primary-color-text) !important;
    font-family: "pop-Regular";
}

/*#endregion STYLE RAW MATERIAL  */

/*#region MODO RESPONSIVO **/
/*#region XS */
@media (max-width: 599.99px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .img-op {
        object-fit: fill;
        height: 200px;
        max-height: 200px;
        min-height: 200px;
        width: 100%;
    }

    .content-empty-carousel {
        height: 220px;
        max-height: 220px;
        min-height: 220px;
        width: 100%;
    }

    .btn-arrow-left-raw-material {
        position: absolute;
        left: 1vw;
        top: 60vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }

    .icon-arrow-left-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 5px;
    }

    .icon-arrow-right-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 0px;
    }

    .btn-arrow-right-raw-material {
        position: absolute;
        /* right: 350px; */
        right: 1vw;
        top: 60vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .img-op {
        object-fit: fill;
        height: 300px;
        max-height: 300px;
        min-height: 300px;
        width: 100%;
    }

    .content-empty-carousel {
        height: 320px;
        max-height: 320px;
        min-height: 320px;
        width: 100%;
    }

    .btn-arrow-left-raw-material {
        position: absolute;
        left: 1vw;
        top: 45vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }

    .icon-arrow-left-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 5px;
    }

    .icon-arrow-right-raw-material {
        color: var(--primary-color-text-arrow-carousel);
        margin-left: 0px;
    }

    .btn-arrow-right-raw-material {
        position: absolute;
        /* right: 350px; */
        right: 1vw;
        top: 45vh;
        background-color: var(--primary-color-background-btn-arrow) !important;
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }


}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }

}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO **/
</style>